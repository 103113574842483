<!--
 * @Descripttion: 纵有千古，横有八荒，前途似海，来日方长。
 * @version: 1.0
 * @Author: 番茄
 * @Date: 2021-12-31 14:51:45
 * @LastEditors: 番茄
 * @LastEditTime: 2022-01-07 11:14:57
-->
<template>
  <div class="booklist-container">
    <nut-searchbar v-model="searchValue"
                   background="rgb(255, 218, 49)"
                   input-background="#fff"
                   placeholder="搜点你想看的">
      <template v-slot:leftout>
        <nut-icon @click="clickLeft"
                  size="20"
                  name="left"></nut-icon>
      </template>
      <template v-slot:rightout>
        <nut-icon name="search"
                  @click="searchBook"></nut-icon>
      </template>
    </nut-searchbar>

    <div class="book">
      <nut-skeleton width="250px"
                    height="15px"
                    title
                    animated
                    avatar
                    :loading="showData"
                    row="8">
        <div class="book-box"
             v-if="bookList.length">
          <div v-for="(item, index) in bookList"
               :key="index"
               @click="toChapter(item)">
            <img :src="item.bookImg"
                 alt="" />
            <div class="book-desc">
              <p class="title">{{ item.bookName }}</p>
              <p class="desc">{{ item.bookDesc }}</p>
              <p>{{ item.bookAuthor }}</p>
            </div>
          </div>
        </div>

        <div class="error-nodata"
             v-else>
          <div>🍅</div>
          <p>没有找到这本书，加钱我给你亲自找</p>
        </div>
      </nut-skeleton>
    </div>

    <nut-backtop el-id="elId"
                 :distance="200">
      <div>🍅</div>
    </nut-backtop>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { search } from '@/api/search'
import { useRoute, useRouter } from 'vue-router'
import { Notify } from '@nutui/nutui'
export default {
  setup() {
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      bookList: [],
      searchValue: route.query.search,
      showData: false,
    })

    onMounted(() => {
      searchBook()
    })

    const searchBook = () => {
      if (state.searchValue) {
        state.showData = true
        search({ bookName: state.searchValue })
          .then((res) => {
            state.bookList = [...res.data]
            state.showData = false
          })
          .catch((e) => {
            state.showData = false
            state.bookList = []
            Notify.warn('请求失败，请再试一次吧')
          })
      } else {
        Notify.warn('请先输入要搜索的书籍')
      }
    }

    const clickLeft = () => {
      router.go(-1)
    }

    // const toChapter = (item) => {
    // 	router.push({
    // 		path: '/chapter',
    // 		query: { bookItem: item.bookPath },
    // 	})
    // }
    const toChapter = (item) => {
      let data = JSON.parse(localStorage.getItem('bookList')) || []
      let flag = data.find((v) => v.bookPath === item.bookPath)

      if (flag) {
        router.push({
          name: 'chapter',
          params: { bookItem: JSON.stringify(flag), isFromHome: true },
        })
      } else {
        router.push({
          name: 'chapter',
          params: { bookItem: JSON.stringify(item) },
        })
      }
    }
    return {
      ...toRefs(state),
      searchBook,
      clickLeft,
      toChapter,
    }
  },
}
</script>

<style lang="scss" scoped>
.booklist-container {
  // height: 100%;
  // background: linear-gradient(91deg, #f1eefc, #9dc6ff 70%, #a5bcff);

  ::v-deep .skeleton {
    margin-top: 30px;
    margin-left: 15px;
  }

  ::v-deep .nut-searchbar {
    position: fixed;
    top: 0;
    left: 0;
  }
  .book {
    margin-top: 50px;
  }

  .error-nodata {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 200px;
    > p {
      margin-top: 10px;
      color: rgb(199, 88, 149);
    }
  }

  .book-box {
    padding: 15px 25px;
    font-size: 18px;
    background: linear-gradient(91deg, #f1eefc, #9dc6ff 70%, #a5bcff);

    > div {
      display: flex;
      margin-bottom: 30px;
      overflow: hidden;
    }

    img {
      width: 120px;
      height: 150px;
      margin-right: 10px;
    }
  }
  .book-desc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      font-weight: 700;
    }
    .desc {
      margin: 10px 0;
      flex: 1;
      font-size: 15px;
      // color: #ccc;
    }
  }
}
</style>
